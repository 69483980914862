$layout-breakpoint-small: 576px;

.bg-primary {
  background-color: #fff !important;
  //background: url('./woman.png');
  //height: 226px!important;
  //background-position: center;
  //background-size: cover;
}

.card-box img {
  position: absolute;
  top: 10px;
  left: 20px;
  height: 80px;
  //filter: brightness(0) invert(1);
  //filter: drop-shadow(-4px 7px 4px #fff);
}

.tx-white-7 {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: $layout-breakpoint-small) {
  .card-box img {
    position: absolute;
    top: 15px;
    left: auto;
    right: 20px;
    height: 35px;
  }
}
