.list-card {
  background: #f6f6fb;
  padding: 14px 18px;
  border-radius: 11px;
  margin-bottom: 20px;
  position: relative;



  .card-item  {
    .card-item-body{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .card-item-stat{
        small{
          font-size: 85%;
          font-weight: 300;
          text-transform: capitalize;
          color: #8d95b1;
        }
      }
    }

  }
  .text-primary {
    color: #6259ca !important;
  }
  .font-weight-semibold {
    font-weight: 500 !important;
  }

}
