.custom-card {
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  margin-bottom: 20px;
  border: 0;
  border-radius: 11px;

  .card-height{
    min-height: 270px;
  }
}

.custom-card-height {
  min-height: 125px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1400px) {
    min-height: 145px;
  }

  @media screen and (max-width: 1234px) {
    min-height: 165px;
  }

  @media screen and (max-width: 1200px) {
    min-height: 100px;
  }
}
