.dashboard-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;

  .dashboard-info-h5 {
    margin-top: 15px;
  }

}