.tx-24 {
  font-size: 24px;
}
.text-primary {
  color: #6259ca !important;
}
.best-emp {
  position: absolute;
  right: 0;
  top: -15px;
  height: 135px;
}
