/*.badge {
  padding: 9px 13px 9px;
}*/
.badge-primary-light {
  background-color: rgba(98, 89, 202, 0.16);
  color: #6259c9;
}
.badge-danger-light {
  background-color: #ffe7e8;
  color: #f16d75;
}
.badge-warning-light {
  background-color: #fdf5e2;
  color: #ebb322;
}
