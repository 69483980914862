.border {
  border: 1px solid #ededf9 !important;
}

.tasks .table th, .tasks .table td {
  padding: 11px 15px;
}

.card-table.table-vcenter th {
  font-weight: 600 !important;
}

.table thead th, .table thead td {
  color: #3c4858;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: .5px;
  text-transform: uppercase;
  border-bottom-width: 1px;
  border-top-width: 0;
  padding: 11px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #e8e8f7;
}

.table th, .table td {
  border-top: none;
}

.table tbody tr {
  background-color: rgba(255, 255, 255, 0.5);
}

.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 0;
}

.taskButton {
  padding: 2px 10px;
  border-radius: 20px;
  font-size: 12px;
  width: 91px;
}
.taskButton2 {
 
  margin-left: 40%;
  margin-right:40%;
 
  padding: 6px 3px;
  border-radius: 20px;
  font-size: 12px;
  width: 81px;
}
.ckbox input[type='checkbox'] {
  opacity: 0;
  margin: 0;
}

.tasks .ckbox span:before, .tasks .ckbox span:after {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.ckbox span:before {
  content: '';
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 1px solid #ccd3e4 !important;
  top: 1px;
  left: 0;
  border-radius: 3px;
}

.ckbox span:before, .ckbox span:after {
  line-height: 18px;
  position: absolute;
}

@media (min-width: 992px) {
  .wd-lg-20p {
    width: 20%;
  }
  .wd-lg-10p {
    width: 10%;
  }
}
