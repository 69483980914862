.bg-primary {
  background-color: #b6b4cb !important;
  //background: url('./woman.png');
  //height: 226px!important;
  //background-position: center;
  //background-size: cover;
}

.card-box img {
  position: absolute;
  bottom: -3px;
  left: -3px;
}

.tx-white-7 {
  color: rgba(255, 255, 255, 0.7);
}
