.card-dashboard-calendar {

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #FFB954;
    border-radius: 20px;
    height: 1px;
  }

  .table-task-div {
    height: 200px;
    overflow-y: scroll;
  }

  .transcations {
    th, td {
      padding: 14px 0;
      line-height: 1.462;
      border-top: 0;
    }

    .wd-5p {
      width: 5%;
    }
  }

  .main-img-user {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .avatar-md {
    width: 40px;
    height: 40px;
    font-size: 12px;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .taskButton {
    padding: 2px 10px;
    border-radius: 20px;
    font-size: 12px;
    width: 81px;
  }

  .font-weight-semibold {
    font-weight: 500 !important;
  }

  .tx-15 {
    font-size: 15px;
  }

  .tx-13 {
    font-size: 13px;
  }

  .tx-11 {
    font-size: 11px;
  }
}
