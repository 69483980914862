.card {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 25px 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
}



.card .map {
    height: 280px;
    border-radius: 6px;
    margin-top: 15px;
}

.card .map.map-big {
    height: 420px;
}

.card .card-title {
    margin-top: 0;
    margin-bottom: 3px;
}


.card .card-image {
    height: 60%;
    position: relative;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
    z-index: 3;
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .card-image img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    pointer-events: none;
}

.card .card-image .card-title {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #fff;
    font-size: 1.3em;
    text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}

.card-product {
    margin-top: 30px;
}

.card-product .footer {
    margin-top: 5px;
}

.card-product .footer .stats .material-icons {
    margin-top: 4px;
    top: 0;
}

.card-product .footer .price h4 {
    margin-bottom: 0;
}

.card-product .card-title,
.card-product .category,
.card-product .card-description {
    text-align: center;
}


