.input-label{
    font-weight: 500;
    span{
        color:#f86c6b;
        padding-left: 2px;
        font-weight: bold;
    }
}
input[type=text]{
    height: auto;
    line-height: 28px;
}
input[type=textarea]{
    height: auto;
    line-height: 42px;
}