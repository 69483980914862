.main-content-label {
  color: #1c273c;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
};

.text-muted {
  color: #8f8fb1 !important;
}
.tx-12 {
  font-size: 12px;
}
