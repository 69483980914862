$small: 350px;
$medium: 1024px;

.login-area {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.logo {
  margin-top: 15px;

  img {
    height: 40px;
    margin-top: 20px;
    margin-left: 30px;
  }
}


.login-area {
  .login-card {
    border: none;
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-body {
    width: 360px;
  }

  .login-text {
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 42px;
    margin-bottom: 30px;
    padding-right: 30px;

    .login-h1 {
      font-weight: 300;
    }

    .login-h2 {
      font-weight: 300;
    }
  }

  .button-groups-settings {
    padding-top: 20px;
  }

  .btn-link {
    &:hover {
      background: transparent;
    }
  }
}

.footer-div {
  padding: 40px 40px 20px 40px;
}

// .login-right {

// }

@media screen and (max-width: $medium) {
  .login-right {
    display: none;
  }
}

@media screen and (max-width: $small) {
  .card-body {
    width: 380px;
  }
}
