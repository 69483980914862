.demo-avatar-group {
  display: flex;
  align-items: center;

  .main-img-user,.main-avatar{
    flex-shrink: 0;
  }
  .main-img-user {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
    .rounded-circle {
      border-radius: 50% !important;
    }
  }

  .avatar-xs {
    width: 24px;
    height: 24px;
    font-size: 11px;
  }

}
.demo-avatar-group .main-img-user+.main-img-user, .demo-avatar-group .main-img-user+.main-avatar {
  margin-left: -9px;
  border: 2px solid #ffffff;
}