.page-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.8rem 0 1.5rem 0;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  min-height: 50px;
  border-radius: 7px;

  .main-content-title {
    color: #170c6b;
    font-weight: 500;
    font-size: 32px;
    text-indent: -1px;
    line-height: 1;
    position: relative;
    margin-bottom: 20px;
  }

 .breadcrumb {
    background: none;
    padding-left: 0 !important;
    padding: 0;
    margin-bottom: 0;
    border:none;
  }
  .tx-24 {
    font-size: 24px;
  }
  .breadcrumb-item a {
    color: #8f9cc0;
  }
  .breadcrumb-item.active {
    color: #7787bc;
  }
}
